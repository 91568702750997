$primary-color: #2D3353;
$primary-color-light: #B5B5B5;
$secondary-color: #FFC632;
$accent-color: #cacaca;
$highlight: #FFC700;

$primary-font: "Montserrat", san-serif;
$secondary-font: 'Source Sans Pro', sans-serif;
$body-font-size: 18px;
$header-font-size: 24px;
$caption-font-size: 16px;
$caption-label-font-size: 14px;
$title-font-size: 60px;

$header-line-height: 0.9;
$body-line-height: 1.4;

$sm: 600px;
$md: 900px;
$lg: 1200px;

@mixin undraggable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
  user-select: none;
  
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -ms-user-drag: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $secondary-font;
  color: $primary-color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $primary-font;
  font-weight: 700;
  line-height: $header-line-height;
}

h1 {
  font-size: $title-font-size;
}

a,
p,
ul,
li {
  font-family: $secondary-font;
  font-weight: 300;
  font-size: $body-font-size;
  text-decoration: none;
  line-height: $body-line-height;
}

img {
  @include undraggable;
}

.hidden {
  opacity: 0;
}

.container {
  height: 100vh;
}

.heading {
  display: inline;
  position: relative;
  outline: 1px solid $primary-color;
  padding: 0.2em 0.25em;
  z-index: 100;
  font-size: $body-font-size;
  &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0.25em;
      left: 0.25rem;
      background: rgba($highlight, 0.65);
      z-index: -1;
  }
}

.label {
  font-family: $secondary-font;
  color: darken($secondary-color, 15%);
  text-transform: uppercase;
  letter-spacing: 1.25px;
  font-weight: 600;
}

.link {
  position: relative;
  display: inline-block;
}

.link:hover {
  color: darken(saturate($secondary-color, 100%), 20%);
  text-decoration: none;
}

.link::before {
  content: "" !important;
  background: rgba($secondary-color, 0.25) !important;
  width: 100% ;
  height: 2px;
  position: absolute;
  bottom: -1px;
  left: 0px;
	transition: all .1s ease-in-out;
}

.link:hover::before{
	height: calc(100% + 2px)
}

@function max($numbers...) {
  @return m#{a}x(#{$numbers});
}

@function min($numbers...) {
  @return m#{i}x(#{$numbers});
}