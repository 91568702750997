$slide-amount: 15px;
$slide-duration: 0.75s;
$fade-duration: 1.5s;

$delays: (0, 1, 2, 3, 4, 5, 6, 7);

/* 
 * classes for  
 * reveal/transition on scroll
 */

@mixin fade($duration) {
  -webkit-animation: fade-in $duration ease;
  -moz-animation: fade-in $duration ease;
  animation: fade-in $duration ease;
}

@mixin slide-and-fade($direction, $duration, $delay) {
  -webkit-animation: fade-in $fade-duration ease #{$delay}s backwards,
    slide-in-#{$direction} $duration ease #{$delay}s backwards;
  -moz-animation: fade-in $fade-duration ease #{$delay}s backwards,
    slide-in-#{$direction} $duration ease #{$delay}s backwards;
  animation: fade-in $fade-duration ease #{$delay}s backwards,
    slide-in-#{$direction} $duration ease #{$delay}s backwards;
}

@each $delay in $delays {
  .transition-in-left-#{$delay} {
    @include slide-and-fade("left", $slide-duration, $delay * 0.3);
  }
}

@each $delay in $delays {
  .transition-in-right-#{$delay} {
    @include slide-and-fade("right", $slide-duration, $delay * 0.3);
  }
}

@each $delay in $delays {
  .transition-in-top-#{$delay} {
    @include slide-and-fade("top", $slide-duration, $delay * 0.3);
  }
}

@each $delay in $delays {
  .transition-in-bottom-#{$delay} {
    @include slide-and-fade("bottom", $slide-duration, $delay * 0.3);
  }
}

/*
 * animaiton definitions  
 *  
 */
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 2;
  }
}

@keyframes fade-in-out {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes slide-in-left {
  0% {
    transform: translateX(-$slide-amount);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slide-in-right {
  0% {
    transform: translateX($slide-amount);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slide-in-top {
  0% {
    transform: translateY(-$slide-amount);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slide-in-bottom {
  0% {
    transform: translateY($slide-amount);
  }
  100% {
    transform: translateY(0);
  }
}
