/* Styling for the header */

.header {
    display: flex;
    align-items: center;
    position: fixed;
    width: 100%;
    z-index: 100;
    top: 0;
    height: 10vh;

    &-content {
        margin: 0 1.5em;
    }
}

.back-btn {
    display: flex;
    align-items: center;
    color: $primary-color-light;
    transition: color 0.25s ease;
    & > svg {
        stroke: $primary-color-light;
        transition: stroke 0.25s ease;
    }
    &:hover > svg {
        stroke: $primary-color;
    }
    &:hover {
        color: $primary-color;
    }
    &_text {
        color: inherit;
    }
}

@media (max-width: $md) {
    .back-btn {
        margin-left: 1rem;
    }
    .header-content.hide {
        display: none;
    }
    .back-btn_text {
        visibility: hidden;
    }
}