
.portfolio {
    width: max(700px, 50vw);
    position: absolute;
    left: 40vw;
    padding: 7vh 0 5px 0;

    .portfolio-content {
        margin-top: 5px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}


.project {
    flex: 0 50%;
    height: 300px;
    padding: 5px 0;
    overflow: hidden;

    &:nth-child(even) {
        padding-left: 5px;
    }
    &:nth-child(odd) {
        padding-right: 5px;
    }

    .project-content {
        width: 100%;
        height: 100%;
        padding: 20px;
        
        background-position: center;
        background-size: 125%;
        transition: background-size 0.7s ease;
        
        &:hover{
            background-size: 130%;
        }

        .project-desc_title,
        .project-desc_year,
        .project-desc_summary {
            text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
        }

        .project-desc{ 
            height:100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            cursor: pointer;
            transition: transform 0.5s ease;            
            &:hover {
                transform: translateY(-20%);
            }
        }
        .project-desc_title,
        .project-desc_year {
            font-family: $primary-font;
            color: white;
        }
        
        .project-desc_title {
            font-size: $header-font-size;
        }
        
        .project-desc_year {
            font-size: $body-font-size;
            color: $secondary-color; 
        }
        
        .project-desc_summary {
            font-size: $body-font-size;
            color: white;
            max-height: 0;
            opacity: 0;
            margin-top: 5px;
            transition: height 1s, opacity 1s;
        }
        .project-desc:hover .project-desc_summary {
            max-height: auto;
            opacity: 1;
        }
    }
}

.project:first-child,
.project:nth-child(2) {
    padding-top: 0;
}

.project-link.link {
    cursor: pointer;
    display: inline;
    font-weight: 600;
    color: darken(saturate($secondary-color, 100%), 20%);
    
    &:hover {
        color: darken(saturate($secondary-color, 100%), 20%);
    }

    &:not(:last-child) {
        margin-right: 0.5rem;
    }
}

.project-page {
    padding-top: 5rem;
    padding-bottom: max(10vh, 4.5em);
    width: 60%;
    margin: auto;
    
    &_intro {
        &-info  {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            width: 60%;
            margin-bottom: 1rem;
            font-size: $body-font-size;
        }
        &-links {
            margin-top: 1rem;
        }
        &-tags {
            margin: 1rem 0;
        }

        img {
            width: 100%;
        }
        h1 {
            width: 100%;
            margin-bottom: 1rem;
        }
        
        &-btn {
            position: relative;
            display: inline;
            padding: 0.25em 0;
            font-family: $primary-font;
            font-weight: 700;
            font-size: $body-font-size;
            outline: none;
            border: none;
            background: none;
            cursor: pointer;
            color: $primary-color;
            margin-right: 1rem;
            &::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 35%;
                top: 0.9em;
                background: rgba($highlight, 0.65);
                z-index: -1;
                transition: all 0.2s ease;
            }
            &:hover::before {
                top: 1.5em;
            }
        }
    }

    &_section {
        margin: 1.25em 0 4em 0;
        > p {
            margin-top: 1em;
        }
    }
}
.caption {
    text-align: center;
    font-style: italic;
    color: $primary-color-light;  
}

.tag {
    padding: 0.15rem 0.55rem 0.2rem 0.55rem;
    font-size: $caption-font-size;
    border: 1px solid saturate(darken($secondary-color, 20%), 50%);
    color: saturate(darken($secondary-color, 20%), 50%);
    border-radius: 100rem;

    &:not(:last-child) {
        margin-right: 0.25rem;
    }
}



@media (max-width: $lg) {
    .project-page,
    .portfolio {
        width: 80vw;
        margin: auto;
    }

    .project-page {
        padding-top: 5.5rem;
        &_intro-info {
            width: 85%;
        }
    }

    .portfolio {
        position: static;
        padding: 5vh 0 10vh 0;
    }

}

@media (max-width: $md) {
    .project {
        flex: 0 100%; 
        height: 400px;
        padding: 0;
        margin-bottom: 1rem;
        padding-left: 0 !important;
        padding-right: 0 !important;
        &-page > img {
            width: 100%;
        }
    }

} 

@media (max-width: $sm) {
    .portfolio {
        padding: 0 0 10vh 0;
        width: 100vw;
    }
    .project {
        margin-right: 0; 
        height: 500px;
        width: 100%;
        &-content {
            background-size: auto 100% !important;
            &:hover {
                background-size: auto 110% !important;
            }
        }
        &-page {
            width: 80%;
            &_intro {
                margin-bottom: 2rem;
                &-info {
                    width: 100%;
                }
            }
            &_section {
                margin: 2em 0 4em 0;
                > p {
                    margin-top: 1.5em;
                }
            }
        }
    }
}