.sidebar {
    position: fixed;
    width: 40vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;

    &-contact {
        display: none;
    }
}

.sidebar-content {
    width: 65%;
    margin: auto;

    .sidebar-name {
        font-size: 60px;
        margin-bottom: 20px;
    }
    .sidebar-cur{
        margin-top: 1.25rem;
        
        &_wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0.3em;
        }
        
        &_header {
            min-width: 5.75rem;
            &.heading {
                min-width: auto;
                font-size: $caption-label-font-size !important;  
            }
        }
        
        .sidebar-cur_content,
        .sidebar-cur_header {
            display: inline;
            font-size: $caption-font-size;
        }

        .sidebar-cur_content {
            flex-grow: 1;
            margin-left: 1rem;
            font-size: $caption-font-size;
        }

    }
}

.contacts {
    margin-top: 2rem;

    &:hover a {
        opacity: 0.7;
    }
    a {
        @include undraggable;
        transition: opacity 0.2s ease-in-out;
        &:hover {
            opacity: 1;
        }
        &:not(:last-child) .logo {
            margin-right: 0.75rem;
        }
    }
    

}

@media (max-width: $lg) {
    .sidebar {
        position: static;
        width: 100vw;
        height: auto;
        padding-top: 5.5rem;

        &-content {
            width: 80vw;
            .sidebar-cur {
                width: fit-content;
                &_wrapper {
                    margin: 0 auto 0.3em auto;
                    justify-content: flex-start;
                    align-items: center;
                    text-align: left;
                }
                &_content {
                    white-space: nowrap;
                    width: auto !important;
                }
            }
        }
        &-about {
            width: 550px;
        }
    }
}
@media (max-width: $md) {
    .sidebar-about {
        width: 85%;
    }
}
@media (max-width: $sm) {
    .sidebar {
        &-contact {
            display: block;
            margin: 0.75rem 0;
            > img:first-child {
                margin-right: 0.5rem;
            }
        }

        &-about {
            width: 100%;
        }

        &-content {
            width: 80%; 
            text-align: left; 
            margin-bottom: 2rem;

            .sidebar-cur {
                display: none; 
            }
        }
    }
}
